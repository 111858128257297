import { environment } from './../../environments/environment';
import { Injectable, EventEmitter, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';


@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  @Output() changeLogged: EventEmitter<any> = new EventEmitter();

  constructor(
    private http: HttpClient,
    private jwtHelper: JwtHelperService,     
  ) { }

  login(model) {
    return this.http.post<any>(`${environment.api}/usuarios`, model)
      .pipe(map(user => {
        // console.log(user);
        if (user && user.token) {
          localStorage.setItem(environment.nameToken, user.token);
        }
        if (user && user.message) {
          

          // this.toasts.error('Error', user.message, {
          //   timeOut: 10000,
          //   positionClass: 'toast-top-full-width',
          //   progressBar: true
          // });
        }
        this.changeLogged.emit();
        return user;
      }));
  }
 
  logout() {
    localStorage.removeItem(environment.nameToken);
    this.changeLogged.emit();
  }

  set(token) {
    localStorage.setItem(environment.nameToken, token);
  }

  get() {
    return localStorage.getItem(environment.nameToken);
  }

  decodeToken(): any {
    return this.jwtHelper.decodeToken(localStorage.getItem(environment.nameToken));
  }

  isTokenExpired () {   
    return this.jwtHelper.isTokenExpired();
  }

 



}
